<template>
  <v-card elevation=4 class="rounded-lg" outlined :loading="logs.length === 0">
    <v-card-title primary-title>
      Audit Log
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-card-subtitle>
      An audit log of all changes made.
    </v-card-subtitle>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="logs"
        :items-per-page="10"
        class="elevation-0"
        :search="search"
        sort-by="auditLog_id"
        :sort-desc="true"
      >
        <template v-slot:[`item.user_name`]="{item}">
          {{ item.user_name ? item.user_name : 'System' }}
        </template>
        <template v-slot:[`item.changes`]="{item}">
          <span v-html="item.changes"/>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: {
      logs: Array,
    },
    data () {
      return {
        headers: [
          { text: 'User', value: 'user_name', align: 'center' },
          { text: 'Changes', sortable: false, value: 'changes', align: 'center' },
          { text: 'Type', value: 'type', align: 'center' },
          { text: 'Action', value: 'action', align: 'center' },
          { text: 'Timestamp', value: 'timestamp', align: 'center'},
        ],
        search: '',
      }
    }
  }
</script>