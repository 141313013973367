export const auditLogsLogic = {
  methods: {
    getAuditLogs(object_id, page) {
      return this.$axios.get(`${process.env.VUE_APP_API_BASE_URL}/auditLogs/${object_id}?page=${page}`)
        .then(result => {
          return result.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
}